<template>
  <v-dialog v-model="dialogVisible" width="400" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn text v-bind="$attrs" v-on="on">aus Rechnung</v-btn>
    </template>
    <v-card>
      <v-system-bar window
        >Rechnung auswählen<v-spacer /><v-icon @click="close"
          >mdi-close</v-icon
        ></v-system-bar
      >
      <v-toolbar flat>
        <v-text-field v-model="search" hide-details=""></v-text-field>
      </v-toolbar>
      <v-divider />
      <v-card-text class="pa-0 ma-0">
        <v-list>
          <v-list-item-group v-model="modelValue">
            <v-list-item
              :key="item.id"
              :value="item"
              v-for="item in itemsFiltered"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.description }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.participants.length }} Personen
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="" text @click="close">Abbrechen</v-btn>
        <v-spacer />
        <v-btn
          color="success"
          text
          @click="accept"
          :disabled="modelValue == null"
          >Personen hinzufügen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "PersonEventPicker",
  props: {
    value: { type: Array },
  },
  data() {
    return {
      dialogVisible: false,
      items: [],
      search: "",
      modelValue: this.value,
      pickerValue: null,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          !this.search ||
          el.description.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  async created() {
    this.items = await this.apiList({
      resource: "finance/eventoverview",
    });
  },

  methods: {
    accept() {
      if (this.modelValue) {
        this.$emit("input", this.modelValue);
        this.close();
      }
    },
    add(item) {
      if (item) {
        this.items.unshift(item);
        this.modelValue = item;
        window.setTimeout(() => (this.pickerValue = null), 100);
      }
    },
    close() {
      this.dialogVisible = false;
    },
  },
});
</script>
